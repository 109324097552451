"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.includes");
require("core-js/modules/es.string.includes");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _some = _interopRequireDefault(require("lodash/some"));
var _every = _interopRequireDefault(require("lodash/every"));
var _filter = _interopRequireDefault(require("lodash/filter"));
var _flatMap = _interopRequireDefault(require("lodash/flatMap"));
var _toLower = _interopRequireDefault(require("lodash/toLower"));
var _words = _interopRequireDefault(require("lodash/words"));
var _toString = _interopRequireDefault(require("lodash/toString"));
var _formatDate = _interopRequireDefault(require("./format-date"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/**
 * Returns a filtered collection that only contains items whose
 * displayed fields contains text matching each search input word.
 *
 * Each item in the collection is kept in the returned filtered collection
 * if any of that item's values at the paths (from the searchConfig array)
 * in that item at least partially match each search word.
 *
 * @param {*} collection the collection to filter
 * @param {*} searchInput the search string used to filter
 * @param {*} searchConfig an array of objects with paths to values
 * in each item in the collection to filter against. Each object may also
 * contain an isDate boolean, which will match number search words if the
 * year, month or day components completely match a word.
 * Example: [ { path: 'dateVal', isDate: true }, { path: 'exampleObj.textVal' } ]
 */
function searchFilter(collection, searchInput, searchConfig) {
  var searchWords = (0, _words.default)((0, _toLower.default)(searchInput), /[^(/|\-|\s)]+/g);
  return (0, _filter.default)(collection, function (item) {
    var targets = (0, _flatMap.default)(searchConfig, function (conf) {
      var target = (0, _get.default)(item, conf.path);
      if (conf.isDate) {
        return (0, _words.default)((0, _formatDate.default)(target, 'YYYY MM DD', 'MM/DD/YYYY'));
      }
      if (conf.isInteger) {
        return (0, _words.default)((0, _toString.default)(target));
      }
      return target || [];
    });
    return (0, _every.default)(searchWords, function (word) {
      return (0, _some.default)(targets, function (target) {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(target)) {
          // Numbers much match exactly
          return target === word;
        }
        return (0, _toLower.default)(target).includes(word);
      });
    });
  });
}
var _default = searchFilter;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(searchFilter, "searchFilter", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/search-filter.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/search-filter.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();