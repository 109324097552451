"use strict";

require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function printHtml(_ref) {
  var html = _ref.html,
    title = _ref.title,
    styles = _ref.styles;
  var printWindow = window.open('', 'PRINT', 'height=600,width=800');
  printWindow.document.write("\n    <html>\n    <head>\n    <title>\n        ".concat(title, "\n    </title>\n    <style>\n        ").concat(styles, "\n    </style>\n    </head>\n    <body>\n        ").concat(html, "\n    </body>\n    </html>\n            "));
  printWindow.document.close();
  printWindow.focus();
  setTimeout(function () {
    printWindow.print();
    printWindow.close();
  }, 10);
}
var _default = printHtml;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(printHtml, "printHtml", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/print-html.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/print-html.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();