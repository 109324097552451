"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_HISTORY = exports.SEARCH = exports.FACILITY_SELECT = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var FACILITY_SELECT = exports.FACILITY_SELECT = '/facility';
var SEARCH = exports.SEARCH = '/search';
var SEARCH_HISTORY = exports.SEARCH_HISTORY = "".concat(SEARCH, "/history");
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(FACILITY_SELECT, "FACILITY_SELECT", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/pages/routes.js");
  reactHotLoader.register(SEARCH, "SEARCH", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/pages/routes.js");
  reactHotLoader.register(SEARCH_HISTORY, "SEARCH_HISTORY", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/pages/routes.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();