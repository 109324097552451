"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearPatientRequests = clearPatientRequests;
exports.clearSearchHistory = clearSearchHistory;
exports.loadSearchHistory = loadSearchHistory;
exports.setHistoryRequestId = setHistoryRequestId;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _get = _interopRequireDefault(require("lodash/get"));
var _set = _interopRequireDefault(require("lodash/set"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _merge = _interopRequireDefault(require("lodash/merge"));
var _values = _interopRequireDefault(require("lodash/values"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _startOfToday = _interopRequireDefault(require("date-fns/startOfToday"));
var _patientFormStore = require("../stores/patient-form-store");
var _queryHelper = require("../common/query/query-helper");
var _patientDiscoveryActions = require("./patient-discovery-actions");
var _routes = require("../components/pages/routes");
var _queryDocumentActions = require("./query-document-actions");
var _documentStore = require("../stores/document-store");
var _queryType = _interopRequireDefault(require("../common/query/query-type"));
var _queryStatus = _interopRequireDefault(require("../common/query/query-status"));
var _queryDocumentStatus = _interopRequireDefault(require("../common/query/query-document-status"));
var _medicationsActions = require("./medications-actions");
var _getMedEntitlement = _interopRequireDefault(require("../common/util/get-med-entitlement"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function clearSearchHistory(context) {
  context.dispatch('CLEAR_SEARCH_HISTORY');
}
function loadSearchHistory(context, _ref) {
  var options = _ref.options,
    params = _ref.params,
    history = _ref.history;
  var parentRequestId = options.parentRequestId;
  context.dispatch('SET_HISTORY_IS_LOADING', true);

  // clear out the existing stores
  (0, _queryHelper.clear)(context);
  var patientSearchRequest = null;
  var historyData = {
    data: {},
    flags: {},
    hasError: false
  };

  // standard behavior for all errors
  var handleError = function handleError() {
    historyData.handleError = true;
    (0, _queryHelper.clear)(context);
    history.push(_routes.SEARCH);
    context.dispatch('SET_HISTORY_IS_LOADING', false);
  };

  // called after each part of the search history is retrieved.
  // checks if all pieces have been retrieved and then ends the search and
  // populates the stores if it has
  var historyPropertyCallback = function historyPropertyCallback(err, service) {
    if (historyData.hasError) {
      return;
    }
    if (err || (0, _get.default)(service, 'response.request.status') !== 200) {
      handleError();
      return;
    }
    historyData.flags[service.id] = true;
    historyData.data[service.id] = (0, _get.default)(service, 'response.data');
    if (historyData.flags['searchHistory.retrievePatientRequests'] && historyData.flags['searchHistory.retrievePatientResults'] && historyData.flags['searchHistory.retrieveQdRequests'] && historyData.flags['searchHistory.retrieveQdResults'] && historyData.flags['searchHistory.retrieveRdRequests'] && historyData.flags['searchHistory.retrieveRdResults']) {
      // we have retrieved all of the history data
      // fix it up and add it to the stores
      var patientRequests = historyData.data['searchHistory.retrievePatientRequests'];
      var patientResults = historyData.data['searchHistory.retrievePatientResults'];
      var qdRequests = historyData.data['searchHistory.retrieveQdRequests'];
      var qdResults = historyData.data['searchHistory.retrieveQdResults'];

      // verify that the patient request is valid
      (0, _forEach.default)(patientRequests, function (item) {
        var id = (0, _get.default)(item, 'parentRequestId');
        if (id === parentRequestId) {
          patientSearchRequest = item;
        }
      });
      if (!patientSearchRequest) {
        handleError();
        return;
      }
      var patientResultsMap = {};
      var selectedPatientsMap = {};
      (0, _forEach.default)(patientResults, function (item) {
        if (item.patientId && item.patientId.length > 0) {
          var patientId = (0, _get.default)(item.patientId[0], 'patientId');
          if (patientId) {
            patientResultsMap[patientId] = item;
          }
        }
      });
      var qdRequestsMap = {};
      (0, _forEach.default)(qdRequests, function (request) {
        qdRequestsMap[request.requestId] = request;
      });
      var qdResultsMatchingRequests = [];
      (0, _forEach.default)(qdResults, function (result) {
        var requestId = result.requestId;
        if (qdRequestsMap[requestId]) {
          var newResult = _objectSpread({}, result);
          var request = qdRequestsMap[requestId];
          newResult.patientId = request.patient;
          qdResultsMatchingRequests.push(newResult);

          // select all patients for this document
          if (newResult.patientId && newResult.patientId.length > 0) {
            var patientId = (0, _get.default)(newResult.patientId[0], 'patientId');
            if (patientId && patientResultsMap[patientId]) {
              var documents = (0, _get.default)(result, 'documents', []);
              selectedPatientsMap[patientId] = patientResultsMap[patientId];
              if (documents.length) {
                patientResultsMap[patientId].status = _queryDocumentStatus.default.SUCCESS;
              } else {
                patientResultsMap[patientId].status = _queryDocumentStatus.default.NO_RESULTS;
              }
            }
          }
        }
      });
      context.dispatch('ADD_PATIENT_RESULT', {
        results: (0, _values.default)(patientResultsMap)
      });

      // select patients
      (0, _forEach.default)((0, _values.default)(selectedPatientsMap), function (patient) {
        (0, _patientDiscoveryActions.selectPatient)(context, patient);
      });
      (0, _forEach.default)(qdResultsMatchingRequests, function (item) {
        context.dispatch('ADD_QUERY_DOCUMENT_RESULT', {
          results: item
        });
      });

      // set the selected doc type
      if (qdResultsMatchingRequests.length > 0) {
        (0, _queryDocumentActions.setSelectedDocType)(context, _documentStore.DocTypes.DOCUMENTS);
      }

      // set the patient fields
      var patientFields = (0, _cloneDeep.default)(patientSearchRequest.patientDemographics);
      var formattedFields = (0, _merge.default)({}, _patientFormStore.blankFields, patientFields);

      // convert fields to formats used in the form
      formattedFields.dob = (0, _parse.default)(patientFields.dob, 'yyyyMMdd', (0, _startOfToday.default)());
      context.dispatch('SET_PD_REQUEST_ID', parentRequestId);
      context.dispatch('SET_PATIENT_FORM_FIELDS', formattedFields);

      // set query statuses
      context.dispatch('ADD_QUERY', {
        id: patientSearchRequest.parentRequestId,
        type: _queryType.default.PATIENT_DISCOVERY,
        status: _queryStatus.default.COMPLETE
      });
      (0, _forEach.default)(qdRequests, function (item) {
        context.dispatch('ADD_QUERY', {
          id: item.requestId,
          type: _queryType.default.QUERY_DOCUMENT,
          status: _queryStatus.default.COMPLETE
        });
      });
      context.dispatch('SET_HISTORY_IS_LOADING', false);
      var hasMedEntitlement = (0, _getMedEntitlement.default)(context);
      (0, _set.default)(patientFields, 'fromSearchHistory', true);
      if (hasMedEntitlement) {
        // make the medication request based on the patient request
        (0, _medicationsActions.queryMedications)(context, {
          fields: patientFields
        });
      }
    }
  };

  // begin loading
  context.service.searchHistory.retrievePatientRequests({
    options: options,
    params: params
  }, historyPropertyCallback);
  context.service.searchHistory.retrievePatientResults({
    options: options,
    params: params
  }, historyPropertyCallback);
  context.service.searchHistory.retrieveQdRequests({
    options: options,
    params: params
  }, historyPropertyCallback);
  context.service.searchHistory.retrieveQdResults({
    options: options,
    params: params
  }, historyPropertyCallback);
  context.service.searchHistory.retrieveRdRequests({
    options: options,
    params: params
  }, historyPropertyCallback);
  context.service.searchHistory.retrieveRdResults({
    options: options,
    params: params
  }, historyPropertyCallback);
}
function clearPatientRequests(context) {
  context.dispatch('SET_SEARCH_HISTORY', []);
}
function setHistoryRequestId(context, id) {
  context.dispatch('SET_HISTORY_REQUEST_ID', id);
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(clearSearchHistory, "clearSearchHistory", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/actions/search-history-actions.js");
  reactHotLoader.register(loadSearchHistory, "loadSearchHistory", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/actions/search-history-actions.js");
  reactHotLoader.register(clearPatientRequests, "clearPatientRequests", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/actions/search-history-actions.js");
  reactHotLoader.register(setHistoryRequestId, "setHistoryRequestId", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/actions/search-history-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();