"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearPatientFormFields = clearPatientFormFields;
exports.setPatientFormFields = setPatientFormFields;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function clearPatientFormFields(context) {
  context.dispatch('CLEAR_PATIENT_FORM_FIELDS');
}
function setPatientFormFields(context, fields) {
  context.dispatch('SET_PATIENT_FORM_FIELDS', fields);
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(clearPatientFormFields, "clearPatientFormFields", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/actions/patient-form-actions.js");
  reactHotLoader.register(setPatientFormFields, "setPatientFormFields", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/actions/patient-form-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();