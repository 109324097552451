"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = {
  '2b03c195-d9ca-405a-9492-778cc668cae9': [{
    facilityCode: 'FACCODE8',
    locationId: '4639daaa-8417-4aa5-9adc-79e3565a6e8c',
    facilityId: '0f108d13-a1f5-4a88-97a9-6c063b96e9a6',
    facilityName: 'East Main Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE7',
    locationId: '4639daaa-8417-4aa5-9adc-79e3565a6e8c',
    facilityId: '42321711-3e8f-4c5e-a51f-0d9f80a4bba3',
    facilityName: 'East Main Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE6',
    locationId: '4639daaa-8417-4aa5-9adc-79e3565a6e8c',
    facilityId: '6d9f8a36-5535-4a94-b24a-e44b610c8273',
    facilityName: 'East Baltimore Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE9',
    locationId: '4639daaa-8417-4aa5-9adc-79e3565a6e8c',
    facilityId: 'b22d6037-de54-424d-af48-2c8ec34e5554',
    facilityName: 'East Main Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE10',
    locationId: '4f66036e-5cd0-4888-8cd6-60250c7a5053',
    facilityId: '87d4fd8b-d4aa-4241-9b81-b333761e994a',
    facilityName: 'East Main Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE11',
    locationId: 'a02cc9ea-6548-49fa-a7c4-968b41159f4d',
    facilityId: 'f485f852-c9b5-4e10-a298-3e2121fdabc6',
    facilityName: 'New Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE12',
    locationId: 'a39cf443-1b0c-49ac-afb5-6b1355e9a452',
    facilityId: '69b23ccf-52fa-4c8f-90df-c00d1d423de4',
    facilityName: 'East Main Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }, {
    facilityCode: 'FACCODE13',
    locationId: 'a39cf443-1b0c-49ac-afb5-6b1355e9a452',
    facilityId: 'cab9fb6c-6071-401e-bd7f-9e737a8e6766',
    facilityName: 'East Main Facility',
    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9'
  }]
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/mock/data/facilities.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();