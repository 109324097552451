"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function beforeRequestEpraPreferences(context) {
  context.dispatch('RETRIEVE_EPRA_PREFERENCES_START');
}
function retrieveEpraPrefsSuccess(context, _ref) {
  var response = _ref.response;
  context.dispatch('RETRIEVE_EPRA_PREFERENCES_FINISH', response ? response.data : {});
}
function retrieveEpraPrefsFailure(context, _ref2) {
  var response = _ref2.response;
  context.dispatch('RETRIEVE_EPRA_PREFERENCES_FAILED', response ? response.data : {});
}
var _default = function _default(config) {
  return {
    serviceName: 'epraPreferences.retrieveEpraPreferences',
    authenticate: true,
    config: {
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.pulse.epraPreferences.retrieveEpraPreferences')
    },
    beforeRequest: beforeRequestEpraPreferences,
    onSuccess: retrieveEpraPrefsSuccess,
    onError: retrieveEpraPrefsFailure,
    onFailed: retrieveEpraPrefsFailure,
    onTimeout: retrieveEpraPrefsFailure,
    onFatal: retrieveEpraPrefsFailure
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(beforeRequestEpraPreferences, "beforeRequestEpraPreferences", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/epra-preferences/retrieveEpraPreferences.js");
  reactHotLoader.register(retrieveEpraPrefsSuccess, "retrieveEpraPrefsSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/epra-preferences/retrieveEpraPreferences.js");
  reactHotLoader.register(retrieveEpraPrefsFailure, "retrieveEpraPrefsFailure", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/epra-preferences/retrieveEpraPreferences.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/epra-preferences/retrieveEpraPreferences.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();