"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function retrieveActivationFacilitiessSuccess(context, _ref) {
  var response = _ref.response;
  context.dispatch('ADD_ACTIVE_FACILITIES', response.data);
}
retrieveActivationFacilitiessSuccess.displayName = 'retrieveActivationFacilitiessSuccess';
var _default = function _default(config) {
  return {
    serviceName: 'tenant.activation.retrieveActivationFacilities',
    authenticate: true,
    config: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.pulse.tenant.activation.retrieveFacilities')
    },
    onSuccess: retrieveActivationFacilitiessSuccess
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(retrieveActivationFacilitiessSuccess, "retrieveActivationFacilitiessSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/activation/retrieve-activation-facilities.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/activation/retrieve-activation-facilities.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();