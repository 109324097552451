"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _pulseFacilitySelection = require("@audacious/pulse-facility-selection");
var _localizationStore = _interopRequireDefault(require("./localization-store"));
var _userStore = _interopRequireDefault(require("./user-store"));
var _patientQueryHistoryStore = _interopRequireDefault(require("./patient-query-history-store"));
var _documentStore = _interopRequireDefault(require("./document-store"));
var _documentViewStore = _interopRequireDefault(require("./document-view-store"));
var _directoryStore = _interopRequireDefault(require("./directory-store"));
var _activeTenantStore = _interopRequireDefault(require("./active-tenant-store"));
var _patientResultsStore = _interopRequireDefault(require("./patient-results-store"));
var _queryStore = _interopRequireDefault(require("./query-store"));
var _searchHistoryStore = _interopRequireDefault(require("./search-history-store"));
var _patientFormStore = _interopRequireDefault(require("./patient-form-store"));
var _medicationsResultsStore = _interopRequireDefault(require("./medications-results-store"));
var _epraPreferencesStore = _interopRequireDefault(require("./epra-preferences-store"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = [_localizationStore.default, _userStore.default, _patientQueryHistoryStore.default, _documentStore.default, _directoryStore.default, _activeTenantStore.default, _patientResultsStore.default, _queryStore.default, _documentViewStore.default, _searchHistoryStore.default, _patientFormStore.default, _medicationsResultsStore.default, _epraPreferencesStore.default].concat((0, _toConsumableArray2.default)(_pulseFacilitySelection.stores));
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/stores/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();