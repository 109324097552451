"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merge = _interopRequireDefault(require("lodash/merge"));
var _split = _interopRequireDefault(require("lodash/split"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/**
 * Returns the domain for APIs
 * Example domain: dev-ainqapps
 * See https://wiki.ainq.com/display/SaaS/Platform+URI+Composition
 */
function getDomain() {
  var domainSections = (0, _split.default)(window.location.hostname, '.');
  domainSections.pop(); // Pop off "com"

  return domainSections.pop() || 'dev-ainqapps';
}
var DEFAULT_CONFIG = {
  eventHandlers: [],
  url: "https://api.".concat(getDomain(), ".com/patientDiscoveryResponse"),
  // url: `http://localhost:80/patientDiscoveryResponse`,
  // url: 'http://localhost:80/medicationsResponse',
  connectionOptions: null,
  autoConnect: true,
  connectImmediately: false,
  // onError: (context, error, errorType) => {
  //     if (error.message !== 'transport close') {
  //         context.notify.warning({
  //             message: `Socket error of type ${errorType}: ${error.message} `,
  //         });
  //     }
  // },
  mocks: {
    enabled: false
  }
};
var _default = function _default() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _merge.default)(DEFAULT_CONFIG, config);
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(getDomain, "getDomain", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/socket/index.js");
  reactHotLoader.register(DEFAULT_CONFIG, "DEFAULT_CONFIG", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/socket/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/socket/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();