"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logoLg = _interopRequireDefault(require("../assets/images/logo-lg.png"));
var _logo = _interopRequireDefault(require("../assets/images/logo.png"));
var _logoOnc = _interopRequireDefault(require("../assets/images/logo-onc.png"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = {
  brand: {
    url: _logoLg.default,
    alt: 'PULSE'
  },
  header: {
    url: _logo.default,
    alt: 'PULSE'
  },
  footer: {
    url: _logoOnc.default,
    alt: 'ONC'
  }
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/localization/images.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();