"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _fluxiblePendo = _interopRequireDefault(require("@audacious/fluxible-pendo"));
var _fluxibleSocketio = _interopRequireDefault(require("@audacious/fluxible-socketio"));
var _client = require("@audacious/client");
require("./styles.css");
var _stores = _interopRequireDefault(require("./stores"));
var _services = require("./services");
var _config = require("./config");
var _application = _interopRequireDefault(require("./components/application"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = function _default(config) {
  var app = new _client.Client({
    components: {
      Application: _application.default
    },
    stores: _stores.default,
    context: (0, _config.createApplicationContext)((0, _get.default)(config, 'context', {})),
    services: (0, _services.createServiceConfiguration)((0, _get.default)(config, 'services', {}))
  });
  app.plug(_fluxiblePendo.default);
  app.plug(_fluxibleSocketio.default);
  function createContext(contextConfiguration) {
    return app.createContext((0, _config.createContextConfiguration)(contextConfiguration));
  }
  return {
    createContext: createContext,
    createDispatcherInstance: app.createDispatcherInstance.bind(app),
    plug: app.plug.bind(app),
    getPlugin: app.getPlugin.bind(app),
    getComponent: app.getComponent.bind(app),
    registerStore: app.registerStore.bind(app),
    dehydrate: app.dehydrate.bind(app),
    rehydrate: app.rehydrate.bind(app)
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();