"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _merge = _interopRequireDefault(require("lodash/merge"));
var _pulseFacilitySelection = require("@audacious/pulse-facility-selection");
var _loadDirectory = _interopRequireDefault(require("./directory/load-directory"));
var _createQuery = _interopRequireDefault(require("./query/create-query"));
var _retrieveResult = _interopRequireDefault(require("./query/retrieve-result"));
var _retrieveDefault = _interopRequireDefault(require("./activation/retrieve-default"));
var _retrieveActivations = _interopRequireDefault(require("./activation/retrieve-activations"));
var _retrieveActivationTargets = _interopRequireDefault(require("./activation/retrieve-activation-targets"));
var _retrieveActivationFacilities = _interopRequireDefault(require("./activation/retrieve-activation-facilities"));
var _retrieveHistoryPatientRequests = _interopRequireDefault(require("./search-history/retrieve-history-patient-requests"));
var _retrieveHistoryPatientResults = _interopRequireDefault(require("./search-history/retrieve-history-patient-results"));
var _retrieveHistoryQdRequests = _interopRequireDefault(require("./search-history/retrieve-history-qd-requests"));
var _retrieveHistoryQdResults = _interopRequireDefault(require("./search-history/retrieve-history-qd-results"));
var _retrieveHistoryRdRequests = _interopRequireDefault(require("./search-history/retrieve-history-rd-requests"));
var _retrieveHistoryRdResults = _interopRequireDefault(require("./search-history/retrieve-history-rd-results"));
var _retrieveEpraPreferences = _interopRequireDefault(require("./epra-preferences/retrieveEpraPreferences"));
var _endpoints = _interopRequireDefault(require("../configs/endpoints"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var DEFAULT_CONFIG = {
  endpoints: _endpoints.default
};
var _default = function _default(config) {
  var configuration = (0, _merge.default)(DEFAULT_CONFIG, config);
  return [(0, _loadDirectory.default)(configuration), (0, _createQuery.default)(configuration), (0, _retrieveEpraPreferences.default)(configuration), (0, _retrieveResult.default)(configuration), (0, _retrieveDefault.default)(configuration), (0, _retrieveActivations.default)(configuration), (0, _retrieveActivationTargets.default)(configuration), (0, _retrieveActivationFacilities.default)(configuration), (0, _retrieveHistoryPatientRequests.default)(configuration), (0, _retrieveHistoryPatientResults.default)(configuration), (0, _retrieveHistoryQdRequests.default)(configuration), (0, _retrieveHistoryQdResults.default)(configuration), (0, _retrieveHistoryRdRequests.default)(configuration), (0, _retrieveHistoryRdResults.default)(configuration)].concat((0, _toConsumableArray2.default)(_pulseFacilitySelection.services));
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(DEFAULT_CONFIG, "DEFAULT_CONFIG", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();