"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PatientFieldsShape = exports.PatientFieldsPropType = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var PatientFieldsShape = exports.PatientFieldsShape = {
  lastName: _propTypes.default.string,
  middleName: _propTypes.default.string,
  firstName: _propTypes.default.string,
  dob: _propTypes.default.string,
  gender: _propTypes.default.object,
  zip: _propTypes.default.string,
  address1: _propTypes.default.string,
  address2: _propTypes.default.string,
  city: _propTypes.default.string,
  state: _propTypes.default.object,
  ssn: _propTypes.default.string,
  homePhone: _propTypes.default.string
};
var PatientFieldsPropType = exports.PatientFieldsPropType = _propTypes.default.shape(PatientFieldsShape);
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(PatientFieldsShape, "PatientFieldsShape", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/patient-fields.js");
  reactHotLoader.register(PatientFieldsPropType, "PatientFieldsPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/patient-fields.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();