"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.includes");
require("core-js/modules/es.string.includes");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _queryHelper = require("../../../common/query/query-helper");
var _queryType = _interopRequireDefault(require("../../../common/query/query-type"));
var _log = _interopRequireDefault(require("../../../common/log"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function retrieveResultSuccess(context, _ref) {
  var response = _ref.response,
    options = _ref.options,
    id = _ref.id;
  var _response$data = response.data,
    _response$data$result = _response$data.results,
    results = _response$data$result === void 0 ? [] : _response$data$result,
    oid = _response$data.oid;
  var queryType = options.queryType,
    queryId = options.queryId,
    associatedIds = options.associatedIds;
  (0, _log.default)('log', 'Retrieve Result Resp: ' + JSON.stringify(response));
  (0, _queryHelper.finishQueryIfComplete)(context, queryId);
  var error = (0, _get.default)(response, 'data.error', {});
  if (queryType === _queryType.default.PATIENT_DISCOVERY) {
    context.dispatch('ADD_PATIENT_RESULT', {
      results: results,
      oid: oid
    });
  } else if (queryType === _queryType.default.QUERY_DOCUMENT) {
    context.dispatch('ADD_QUERY_DOCUMENT_RESULT', {
      results: results,
      oid: oid
    });
    context.dispatch('SET_QUERY_DOCUMENT_STATUS', {
      results: results,
      oid: oid,
      patientIds: associatedIds,
      retrievedResult: true,
      isError: error.returnedError
    });
  } else if (queryType === _queryType.default.RETRIEVE_DOCUMENT) {
    var contentType = response.headers.getContentType();
    var type;
    var data = (0, _get.default)(response, 'data');
    if (contentType.includes('application/pdf')) {
      type = 'pdf';
    } else if (contentType.includes('text/html')) {
      type = 'html';
    } else {
      throw new Error('Invalid data type');
    }
    context.dispatch('SET_RETRIEVE_DOCUMENT_STATUS', {
      results: results,
      isError: error.returnedError
    });
    context.dispatch('UPDATE_TRANSFORMED_DOCUMENT', {
      type: type,
      data: data
    });
  } else if (queryType === _queryType.default.MEDICATIONS) {
    context.dispatch('ADD_MEDICATIONS_RESULTS', {
      results: results,
      error: error.returnedError
    });
  } else {
    throw new Error('Invalid Query Type');
  }
  var resultRequest = {
    serviceId: id,
    results: results
  };
  if (error.returnedError) {
    resultRequest.error = error.error;
  }
  context.dispatch('ADD_QUERY_RESULT', {
    id: queryId,
    resultRequest: resultRequest
  });
}
retrieveResultSuccess.displayName = 'retrieveResultSuccess';
function retrieveResultError(context, _ref2) {
  var options = _ref2.options,
    id = _ref2.id,
    error = _ref2.error;
  var queryType = options.queryType,
    queryId = options.queryId,
    associatedIds = options.associatedIds;
  (0, _log.default)('log', 'Retrieve Result Resp ERROR: ' + JSON.stringify(error));
  (0, _queryHelper.finishQueryIfComplete)(context, queryId);
  context.dispatch('ADD_QUERY_RESULT', {
    id: queryId,
    resultRequest: {
      serviceId: id,
      error: error
    },
    requestError: true
  });
  if (queryType === _queryType.default.QUERY_DOCUMENT) {
    context.dispatch('SET_QUERY_DOCUMENT_STATUS', {
      patientIds: associatedIds,
      isError: true
    });
  } else if (queryType === _queryType.default.RETRIEVE_DOCUMENT) {
    context.dispatch('SET_RETRIEVE_DOCUMENT_STATUS', {
      isError: true
    });
  }
}
retrieveResultError.displayName = 'retrieveResultError';
var _default = function _default(config) {
  return {
    serviceName: 'query.retrieveResult',
    authenticate: true,
    config: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.pulse.query.retrieveResult')
      // baseUrl: 'http://localhost:8080'
    },
    onSuccess: retrieveResultSuccess,
    onFailed: retrieveResultError,
    onError: retrieveResultError,
    onTimeout: retrieveResultError,
    onFatal: retrieveResultError
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(retrieveResultSuccess, "retrieveResultSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/query/retrieve-result.js");
  reactHotLoader.register(retrieveResultError, "retrieveResultError", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/query/retrieve-result.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/query/retrieve-result.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();