"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var defaultLocalization = {
  patientDemoForm: {
    firstNameFieldLabel: 'First Name',
    middleNameFieldLabel: 'Middle Name',
    lastNameFieldLabel: 'Last Name',
    dateOfBirthFieldLabel: 'Date of Birth',
    genderFieldLabel: 'Gender',
    zipCodeFieldLabel: 'Zip Code',
    address1FieldLabel: 'Address',
    address2FieldLabel: 'Address 2',
    cityFieldLabel: 'City',
    stateFieldLabel: 'State',
    ssnFieldLabel: 'SSN',
    phoneFieldLabel: 'Phone'
  },
  documentResults: {
    documents: 'Documents',
    medications: 'Medications',
    conditions: 'Conditions',
    more: 'More',
    clearSelection: 'Clear Patient Selection',
    // TODO: Refactor this out of localization once reqs are decided on
    documentFilterType: 'All',
    table: {
      title: 'Title',
      source: 'Source',
      serviceTime: 'Service Time',
      author: 'Author',
      authorInstitution: 'Author Institution',
      type: 'Type'
    }
  },
  medicationsResults: {
    table: {
      name: 'Medication',
      fillDate: 'Fill Date',
      writtenDate: 'Written Date',
      quantity: 'Qty',
      days: 'Days',
      refills: 'Refills',
      prescriber: 'Prescriber',
      pharmacy: 'Pharmacy'
    }
  },
  patientDemoResults: {
    table: {
      gender: 'Gender',
      patient: 'Patient',
      dob: 'DOB',
      address: 'Address',
      source: 'Source',
      phone: 'Phone',
      status: 'Status'
    }
  },
  acfSelection: {
    title: 'Alternate Care Facility',
    searchPlaceholder: 'Search...',
    continueButton: 'Continue',
    noLocations: 'No active locations available!'
  },
  documentView: {
    errorLabel: 'Unable to retrieve document'
  },
  searchHistory: {
    table: {
      name: 'Patient Name',
      dob: 'Date of Birth',
      gender: 'Gender',
      address: 'Address',
      phone: 'Phone',
      lastAccessed: 'Last Accessed'
    }
  }
};
var _default = defaultLocalization;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(defaultLocalization, "defaultLocalization", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/localization/defaultLocalization.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/localization/defaultLocalization.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();