"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merge = _interopRequireDefault(require("lodash/merge"));
var _loadFacilities = _interopRequireDefault(require("./load-facilities"));
var _loadLocations = _interopRequireDefault(require("./load-locations"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var DEFAULT_MOCK_SERVICES = {
  loadFacilities: _loadFacilities.default,
  loadLocations: _loadLocations.default
};
var _default = function _default(_ref) {
  var _ref$enabled = _ref.enabled,
    enabled = _ref$enabled === void 0 ? false : _ref$enabled,
    _ref$include = _ref.include,
    include = _ref$include === void 0 ? [] : _ref$include,
    _ref$exclude = _ref.exclude,
    exclude = _ref$exclude === void 0 ? [] : _ref$exclude,
    _ref$immediate = _ref.immediate,
    immediate = _ref$immediate === void 0 ? true : _ref$immediate,
    _ref$services = _ref.services,
    services = _ref$services === void 0 ? {} : _ref$services;
  return {
    enabled: enabled,
    include: include,
    exclude: exclude,
    immediate: immediate,
    services: (0, _merge.default)(DEFAULT_MOCK_SERVICES, services)
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(DEFAULT_MOCK_SERVICES, "DEFAULT_MOCK_SERVICES", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/mock/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/mock/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();