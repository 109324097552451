"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patientShape = exports.patientResultsPropType = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var patientShape = exports.patientShape = _propTypes.default.shape({
  patient: _propTypes.default.shape({
    firstName: _propTypes.default.string,
    lastName: _propTypes.default.string,
    middleName: _propTypes.default.string,
    suffix: _propTypes.default.string,
    gender: _propTypes.default.string,
    dob: _propTypes.default.string,
    address1: _propTypes.default.string,
    address2: _propTypes.default.string,
    city: _propTypes.default.string,
    state: _propTypes.default.string,
    zip: _propTypes.default.string,
    homePhone: _propTypes.default.string,
    cellPhone: _propTypes.default.string,
    workPhone: _propTypes.default.string,
    ssn: _propTypes.default.string,
    source: _propTypes.default.string
  }),
  facility: _propTypes.default.string,
  selected: _propTypes.default.bool,
  status: _propTypes.default.string.isRequired
}).isRequired;
var patientResultsPropType = exports.patientResultsPropType = _propTypes.default.shape({
  patients: _propTypes.default.arrayOf(patientShape)
}).isRequired;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(patientShape, "patientShape", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/patient-results.js");
  reactHotLoader.register(patientResultsPropType, "patientResultsPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/patient-results.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();