"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var QueryStatus = {
  CREATING_QUERY: 'creatingQuery',
  RETRIEVING_RESULTS: 'retrievingResults',
  DELAYED: 'delayed',
  COMPLETE: 'complete',
  SOCKET_DISCONNECTED: 'socketDisconnected',
  REQUEST_ERROR: 'requestError',
  TIMEOUT: 'timeout'
};
var _default = QueryStatus;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(QueryStatus, "QueryStatus", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/query/query-status.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/query/query-status.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();