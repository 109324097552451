"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/web.dom-collections.for-each");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function retrieveActivationsSuccess(context, _ref) {
  var response = _ref.response;
  var activations = response.data;
  context.dispatch('SET_ACTIVATIONS', activations);

  // clear existing activation targets

  var userStore = context.getStore('UserStore');
  var userState = userStore.getState();
  var tenantId = (0, _get.default)(userState, 'user.tenantId', '');

  // get activation targets for each active activation
  activations.forEach(function (activation) {
    if (activation.active) {
      var activationId = activation.activationId;
      var options = {
        activationId: activationId,
        tenantId: tenantId
      };
      context.service.tenant.activation.retrieveActivationFacilities({
        options: options
      });

      // context.service.tenant.activation.retrieveActivationTargets({
      //     options,
      // });
    }
  });
}
retrieveActivationsSuccess.displayName = 'retrieveActivationsSuccess';
var _default = function _default(config) {
  return {
    serviceName: 'tenant.activation.retrieveActivations',
    authenticate: true,
    config: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: (0, _get.default)(config, 'endpoints.pulse.tenant.activation.retrieve')
    },
    onSuccess: retrieveActivationsSuccess
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(retrieveActivationsSuccess, "retrieveActivationsSuccess", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/activation/retrieve-activations.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/service-configs/activation/retrieve-activations.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();