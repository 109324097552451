"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = {
  pulse: {
    tenant: {
      target: {
        retrieve: '/pulse/v1/tenanttarget/{tenantId}',
        assign: '/pulse/v1/tenanttarget/{tenantId}/assign'
      },
      activation: {
        create: '',
        retrieve: '/pulse/v1/activation/{tenantId}',
        retrieveDefault: '/pulse/v1/default/activation',
        update: '',
        delete: '',
        retrieveFacilities: '/pulse/v1/facility/{tenantId}/activation/{activationId}'
      },
      activationTarget: {
        retrieve: '/pulse/v1/default/activationtarget/{tenantId}/{activationId}'
      }
    },
    query: {
      create: '/{serviceBasePath}/v1/{queryType}',
      retrieveResult: '/{serviceBasePath}/v1/document/{queryType}/{childRequestId}'
    },
    directory: {
      retrieve: '/pulse/v1/directory'
    },
    searchHistory: {
      retrievePatientRequests: '/pulse/v1/searchhistory/{tenantId}/{facilityId}',
      retrievePatientResults: '/pulse/v1/searchhistory/{tenantId}/{facilityId}/{parentRequestId}',
      retrieveQdRequests: '/pulse/v1/searchhistory/{tenantId}/{facilityId}/request/qd/{parentRequestId}',
      retrieveQdResults: '/pulse/v1/searchhistory/{tenantId}/{facilityId}/response/qd/{parentRequestId}',
      retrieveRdRequests: '/pulse/v1/searchhistory/{tenantId}/{facilityId}/request/rd/{parentRequestId}',
      retrieveRdResults: '/pulse/v1/searchhistory/{tenantId}/{facilityId}/response/rd/{parentRequestId}'
    },
    epraPreferences: {
      retrieveEpraPreferences: '/common/v1/tenants/{tenantId}/settings/epraPreferences'
    }
  }
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/configs/endpoints.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();