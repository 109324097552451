"use strict";

require("core-js/modules/es.array.concat");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.string.match");
require("core-js/modules/es.string.replace");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatAddress = formatAddress;
exports.formatGender = formatGender;
exports.formatPatientName = formatPatientName;
exports.formatPhoneNumber = formatPhoneNumber;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
/* eslint-disable no-param-reassign */
function formatPatientName(_ref) {
  var firstName = _ref.firstName,
    middleName = _ref.middleName,
    lastName = _ref.lastName;
  firstName = firstName || '';
  middleName = middleName || '';
  lastName = lastName || '';
  return "".concat(lastName || '').concat(lastName && firstName ? ',' : '', " ").concat(firstName || '', " ").concat(middleName || '');
}
function formatGender(gender) {
  var upperGender = gender || '';
  upperGender = upperGender.toUpperCase();
  if (upperGender === 'M') {
    return 'Male';
  }
  if (upperGender === 'F') {
    return 'Female';
  }
  if (upperGender === 'U') {
    return 'Unknown';
  }
  return '';
}
function formatAddress(_ref2) {
  var address1 = _ref2.address1,
    address2 = _ref2.address2,
    city = _ref2.city,
    state = _ref2.state,
    zip = _ref2.zip;
  address1 = address1 || '';
  address2 = address2 || '';
  city = city || '';
  state = state || '';
  zip = zip || '';
  var result = '';
  var street = '';
  if (address1) {
    street = address1;
    if (address2) street += " ".concat(address2);
  }
  result = street;
  if (city || state || zip) {
    if (result) {
      result += ',';
    }
    if (city) result += " ".concat(city);
    if (city && state) result += ',';
    if (state) result += " ".concat(state);
    if (zip) result += " ".concat(zip);
  }
  return result;
}
function formatPhoneNumber(phoneNumber) {
  var phone = phoneNumber || '';
  var countryCodeMatch = phone.match(/\+[0-9]+/g);
  phone = phone.replace(/\+[0-9]+/g, '');
  var onlyNumPhone = phone.replace(/[^\d]/g, '');
  var formattedPhone = onlyNumPhone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  if (countryCodeMatch) {
    formattedPhone = "".concat(countryCodeMatch[0], " ").concat(formattedPhone);
  }
  return formattedPhone;
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(formatPatientName, "formatPatientName", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/format-patient-fields.js");
  reactHotLoader.register(formatGender, "formatGender", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/format-patient-fields.js");
  reactHotLoader.register(formatAddress, "formatAddress", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/format-patient-fields.js");
  reactHotLoader.register(formatPhoneNumber, "formatPhoneNumber", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/format-patient-fields.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();