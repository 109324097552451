"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = {
  heading: {
    welcome: 'Welcome to PULSE'
  },
  form: {
    support: {
      field: {
        phone: {
          value: '(302) 480-1770'
        },
        email: {
          value: 'servicedesk@pulse.org'
        }
      }
    }
  },
  content: {
    welcome: {
      announcement: 'Patient Unified Lookup System for Emergencies (PULSE) is designed to provide healthcare professionals caring for displaced patients, access to their vital health information in an event of a disaster.\n\nThis health information, and concise health record documents, may be drawn from disparate systems.\n\nThe disparate systems could include HIOs, Ambulatory Practices, Emergency Medical Service, Agencies, etc.'
    },
    support: 'Please contact your PULSE administrator with support questions.'
  }
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/localization/login.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();