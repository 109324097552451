"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatDate;
var _moment = _interopRequireDefault(require("moment"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function formatDate() {
  var dateString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var outDateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'MM/DD/YYYY';
  var inDateFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'YYYYMMDDHHmmss';
  var date = (0, _moment.default)(dateString, inDateFormat);
  date = date.isValid() ? date : (0, _moment.default)(dateString);
  return date.isValid() ? date.format(outDateFormat) : '';
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(formatDate, "formatDate", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/common/util/format-date.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();