"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.documentViewPropType = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var documentViewPropType = exports.documentViewPropType = _propTypes.default.shape({
  data: _propTypes.default.string.isRequired,
  type: _propTypes.default.string.isRequired,
  isError: _propTypes.default.bool
}).isRequired;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(documentViewPropType, "documentViewPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/document-view.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();