"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchHistoryItemShape = exports.searchHistoryItemPropType = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var searchHistoryItemShape = exports.searchHistoryItemShape = {
  originalSearchDate: _propTypes.default.string.isRequired,
  patientDemographics: _propTypes.default.shape({
    firstName: _propTypes.default.string,
    lastName: _propTypes.default.string,
    middleName: _propTypes.default.string,
    suffix: _propTypes.default.string,
    gender: _propTypes.default.string,
    dob: _propTypes.default.string,
    address1: _propTypes.default.string,
    city: _propTypes.default.string,
    state: _propTypes.default.string,
    homePhone: _propTypes.default.string
  })
};
var searchHistoryItemPropType = exports.searchHistoryItemPropType = _propTypes.default.shape(searchHistoryItemShape);
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(searchHistoryItemShape, "searchHistoryItemShape", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/search-history-item.js");
  reactHotLoader.register(searchHistoryItemPropType, "searchHistoryItemPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/search-history-item.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();