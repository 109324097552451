"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localizationShape = exports.default = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var localizationShape = exports.localizationShape = {
  patientDemoForm: {
    firstNameFieldLabel: _propTypes.default.string.isRequired,
    middleNameFieldLabel: _propTypes.default.string.isRequired,
    lastNameFieldLabel: _propTypes.default.string.isRequired,
    dateOfBirthFieldLabel: _propTypes.default.string.isRequired,
    genderFieldLabel: _propTypes.default.string.isRequired,
    zipCodeFieldLabel: _propTypes.default.string.isRequired,
    address1FieldLabel: _propTypes.default.string.isRequired,
    address2FieldLabel: _propTypes.default.string.isRequired,
    cityFieldLabel: _propTypes.default.string.isRequired,
    stateFieldLabel: _propTypes.default.string.isRequired,
    ssnFieldLabel: _propTypes.default.string.isRequired,
    phoneFieldLabel: _propTypes.default.string.isRequired
  },
  documentResults: {
    documents: _propTypes.default.string.isRequired,
    medications: _propTypes.default.string.isRequired,
    conditions: _propTypes.default.string.isRequired,
    more: _propTypes.default.string.isRequired,
    clearSelection: _propTypes.default.string.isRequired,
    documentFilterType: _propTypes.default.string.isRequired,
    table: {
      title: _propTypes.default.string.isRequired,
      source: _propTypes.default.string.isRequired,
      serviceTime: _propTypes.default.string.isRequired,
      author: _propTypes.default.string.isRequired,
      authorInstitution: _propTypes.default.string.isRequired,
      type: _propTypes.default.string.isRequired
    }
  },
  patientDemoResults: {
    table: {
      gender: _propTypes.default.string.isRequired,
      patient: _propTypes.default.string.isRequired,
      dob: _propTypes.default.string.isRequired,
      address: _propTypes.default.string.isRequired,
      source: _propTypes.default.string.isRequired,
      phone: _propTypes.default.string.isRequired,
      status: _propTypes.default.string.isRequired
    }
  },
  acfSelection: {
    title: _propTypes.default.string.isRequired,
    searchPlaceholder: _propTypes.default.string.isRequired,
    continueButton: _propTypes.default.string.isRequired,
    noLocations: _propTypes.default.string.isRequired
  },
  documentView: {
    errorLabel: _propTypes.default.string.isRequired
  },
  searchHistory: {
    table: {
      name: _propTypes.default.string.isRequired,
      dob: _propTypes.default.string.isRequired,
      gender: _propTypes.default.string.isRequired,
      address: _propTypes.default.string.isRequired,
      phone: _propTypes.default.string.isRequired,
      lastAccessed: _propTypes.default.string.isRequired
    }
  }
};
var _default = _propTypes.default.shape(localizationShape).isRequired;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(localizationShape, "localizationShape", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/localization.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/localization.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();