"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.documentShape = exports.documentResultsPropType = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var documentShape = exports.documentShape = _propTypes.default.shape({
  id: _propTypes.default.string.isRequired,
  title: _propTypes.default.string.isRequired,
  source: _propTypes.default.string.isRequired,
  serviceTime: _propTypes.default.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patientId: _propTypes.default.object.isRequired
}).isRequired;
var documentResultsPropType = exports.documentResultsPropType = _propTypes.default.shape({
  documents: _propTypes.default.arrayOf(documentShape)
}).isRequired;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(documentShape, "documentShape", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/documents.js");
  reactHotLoader.register(documentResultsPropType, "documentResultsPropType", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/components/prop-types/documents.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();