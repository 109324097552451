"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.string.split");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = loadLocations;
var _locations = _interopRequireDefault(require("./data/locations"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function loadLocations(_ref) {
  var request = _ref.request,
    resolve = _ref.resolve,
    reject = _ref.reject;
  var parts = request.url.split('/');
  var locations = _locations.default["".concat(parts[parts.length - 1])];
  if (locations) {
    resolve(locations);
  } else {
    reject(404);
  }
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(loadLocations, "loadLocations", "/builds/audacious-inquiry/portfolio/pulse/pulse-patient-search-application/clone/src/services/mock/load-locations.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();